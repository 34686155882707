<template>
  <div class="wrapper">
    <div class="card">
      <div class="title">
        <div class="caption">{{ $t('reset_two_factor_auth') }}</div>
      </div>
      <div class="content">
        <div style="color: #686E74">{{ $t('google.verify.content') }}</div>
        <div class="verificationBox mt-4">
          <div class="hint">
            <div style="display: inline-block">{{ $t('enter_code') }}</div>
          </div>
          <div class="code">
            <CodeComponent ref="child" :attempt="attempt" :limit="limit" @auth="authEvent" @status="statusEvent" />
          </div>
        </div>
        <div class="msg-box">
          <div v-if="msg.status">
            <i class="owl-status-triangle-warning"></i>
            <span>{{ msg.note }}</span>
          </div>
        </div>
        <div>
          <button :disabled="!status" class="btn btn-solid btn-blue" @click="auth">{{ $t('auth') }}</button>
        </div>
        <div class="mt-4">
          <div class="link link-info">
            <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bind } from '../api/2fa'
import CodeComponent from '@/components/CodeComponent.vue'

export default {
  name: 'setting.google.reset.bind',
  components: {
    CodeComponent
  },
  data () {
    return {
      status: false,
      type: 'google',
      msg: {
        status: false,
        note: ''
      },
      attempt: 0,
      limit: 0
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.$route.params.mode === undefined) {
        this.$router.push({
          name: 'setting.index'
        })
      }
    },
    async authEvent () {
      await this.auth()
    },
    statusEvent (status) {
      this.status = status
    },
    async auth () {
      this.status = false
      this.msg.status = false
      const code = this.$refs.child.getCode()
      const data = {
        secret_key: this.$route.params.secret,
        code: code
      }
      const ret = await bind(this.type, data)
      if (!ret.status) {
        this.$refs.child.reset()
        this.msg.status = true
        this.msg.note = ret.msg
        this.status = true
        return
      }
      const url = ret.data.redirect
      this.$router.push({
        name: 'setting.success',
        params: {
          mode: true,
          url: url
        }
      })
    },
    cancelled () {
      this.$router.push({
        name: 'setting.index'
      })
    }
  }
}
</script>
